<app-header></app-header>
<div *ngIf="isLoading$ | async">
  <gem-loading-spinner></gem-loading-spinner>
</div>
<router-outlet></router-outlet>
<div class="mt-3 mb-3 row gib-flex-center">
  <div class="col-12 max-width-1000 text-center">
    <gem-footer></gem-footer>
  </div>
</div>
