import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, ParamMap, Router, RoutesRecognized } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { GemFeedbackComponent } from "gematik-shared";
import { EnvService, TokenHelperService } from "gematik-form-library";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  appName: string = "app_name";
  lang: string;
  langSub: Subscription;
  routerSub: Subscription;
  component: string = "Form";

  constructor(
    private translate: TranslateService,
    private tokenHelperService: TokenHelperService,
    private oauthService: OAuthService,
    private env: EnvService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();

    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });

    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        const paramMap = val.state.root.firstChild.paramMap as ParamMap;
        if (paramMap.has("activity")) {
          this.component = val.state.root.firstChild.params["activity"];
        }
      }
    });
  }

  onLangClick(): void {
    if (this.translate.currentLang === "de") {
      this.translate.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translate.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  onFeedback(): void {
    // this.dialog.open(GemFeedbackDialogComponent, {
    //   data: {
    //     title: this.translate.instant("feedbackTitle"),
    //     text: this.translate.instant("feedbackText"),
    //     backendUrl: this.env.feedbackBackendUrl,
    //     application: "SLR",
    //     component: this.component,
    //   },
    //   width: "450px",
    // });
    this.dialog.open(GemFeedbackComponent, {
      data: {
        backendUrl: this.env.feedbackBackendUrl,
        application: "SLR",
        component: this.component,
      },
      width: "500px",
    });
  }

  onSignOutClick(): void {
    const url = window.location.origin;
    this.oauthService.logOut();
    window.location.href = `https://login.windows.net/${this.env.oauthTennantId}/oauth2/logout?post_logout_redirect_uri=${url}`;
    window.sessionStorage.clear();
  }

  public get username() {
    try {
      return this.tokenHelperService.getFullname();
    } catch (error) {
      return null;
    }
  }

  ngOnDestroy() {
    if (this.langSub) {
      this.langSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
