import { Injectable, Type } from "@angular/core";
import { ServiceLevelDto } from "../../services/service-level.service";

export class SlrFormat {
  value: number;
  suffix: string;
  displayValue: string;
  displayMin: string;
  displayMax: string;

  valueWithSuffix() {
    return this.displayValue + " " + this.suffix;
  }
}

@Injectable({
  providedIn: "root",
})
export class SlrFormatService {
  constructor() {}
  getFormat(input: number, type: number): SlrFormat {
    let slrFormat: SlrFormat = new SlrFormat();
    if (input !== null) {
      slrFormat.value = input;
      slrFormat.displayValue = "" + input;
    } else {
      slrFormat.value = null;
      slrFormat.displayValue = null;
    }
    switch (type) {
      case 1: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "100";
        slrFormat.suffix = "%";
        if (input !== null) {
          let num: number = +input;
          num = num / 1000;
          slrFormat.displayValue = num.toLocaleString("de-DE");
        }
        return slrFormat;
      }
      case 2: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "";
        return slrFormat;
      }
      case 3: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "ms";
        return slrFormat;
      }
      case 4: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "min";
        return slrFormat;
      }
      case 5: {
        //statements;
        break;
      }
      case 6: {
        //statements;
        break;
      }
      case 7: {
        //statements;
        break;
      }
      case 8: {
        //statements;
        break;
      }
      case 9: {
        slrFormat.displayMin = "";
        slrFormat.displayMax = "";
        slrFormat.suffix = "";
        if (input === 1) {
          slrFormat.displayValue = "WAHR";
        } else {
          slrFormat.displayValue = "FLASCH";
        }
        return slrFormat;
      }
      case 10: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "kB";
        return slrFormat;
      }
      case 11: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "Sek.";
        return slrFormat;
      }
      case 12: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "∞";
        slrFormat.suffix = "MBit/s";
        return slrFormat;
      }
      case 13: {
        slrFormat.displayMin = "0";
        slrFormat.displayMax = "100";
        slrFormat.suffix = "%";
        if (input !== null) {
          let num: number = +input;
          slrFormat.displayValue = num.toLocaleString("de-DE");
        }
        return slrFormat;
      }
      default: {
        return null;
      }
    }
  }

  public formatToDataWarehouseValue(input: string, type: number): number | string {
    if (input) {
      const normalized: string = input.toString().replace(/[,.]/g, "");

      if (isNaN(+normalized)) {
        return normalized;
      }

      let num: number = +normalized;
      if (type === 1) {
        if (num === 100) {
          num = 100 * 1000;
        } else {
          const digits: number = num.toString().length;
          for (let i = digits; i < 5; i++) {
            num = num * 10;
          }
        }
      }
      return num;
    }

    return input;
  }

  public getSetpointRange(slDto: ServiceLevelDto, withFactoredMaxValue: boolean = false) {
    let min: SlrFormat = this.getFormat(withFactoredMaxValue ? 0 : slDto.sollMin, slDto.unitId);
    let max: SlrFormat = this.getFormat(
      withFactoredMaxValue && slDto.sollMaxFactored !== 0 ? slDto.sollMaxFactored : slDto.sollMax,
      slDto.unitId,
    );
    let ret = "";
    if (min.value !== null) {
      ret += min.displayValue;
    } else {
      ret += min.displayMin;
    }
    ret += " " + min.suffix + " - ";
    if (max.value !== null) {
      ret += max.displayValue;
    } else {
      ret += max.displayMax;
    }
    ret += " " + max.suffix;
    return ret;
  }

  public isDecimal(slDto: ServiceLevelDto) {
    let u = slDto.unitId;
    if (u === 1 || u === 13) {
      return true;
    }
    return false;
  }

  public isNumber(slDto: ServiceLevelDto) {
    let u = slDto.unitId;
    if (u === 2 || u === 3 || u === 4 || u === 10 || u === 11 || u === 12) {
      return true;
    }
    return false;
  }

  getOriginal(input: string, type: number): number {
    let cleanValue = +input.toString().replace(",", ".");
    if (isNaN(cleanValue)) {
      return NaN;
    }
    switch (type) {
      case 1: {
        return cleanValue * 1000;
      }
    }
    return cleanValue;
  }

  formatReportDate(input: string): string {
    return input.replace(/(\d{4}(?!\s))/g, "$1/");
  }
}
